<template>
  <fieldset>
    <legend
      class="font-semibold"
      :class="hideLabel && 'sr-only'"
    >
      File upload
    </legend>
    <div
      class="flex "
    >
      <button 
        for="file__input"
        class="flex flex-col items-center w-full py-4 text-gray-600 transition-all duration-100 border-2 cursor-pointer rounded-xl hover:border-primary-600" 
        @click="dialog.open"
      >
        <div class="mb-4 text-3xl">
          <FadedIcon
            icon-name="upload"
            color="gray"
          />
        </div>
        <span>
          <span class="font-semibold text-primary-700"> Click to upload file </span> 
          <span> or drag and drop </span> 
        </span>
        <span class="text-xs text-gray-600">
          pdf, pptx or keynote (max 100MB )
        </span>
      </button>
    </div>
  </fieldset>
</template>

<script setup lang="ts">


const props = defineProps({
  acceptedFileTypes: {
    type: Array,
    default: () => ['application/pdf']
  },
  hideLabel: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits<{
  update: [value:File[]],
}>()


const dialog = useFileDialog({
  accept: props.acceptedFileTypes.join(', '), // Set to accept only image files
  multiple: true, // Allow selecting multiple files
})

dialog.onChange((files) => {
  if(!files) return
  
  const listOfFiles = [] as File[]
  [...Array(files.length).keys()].forEach((_,index) => {
    listOfFiles.push(files[index])
  })
  console.log(listOfFiles);
  
  emit('update',listOfFiles)
  dialog.reset()
})

</script>